
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  PropType,
  watch,
} from "vue";
import phonePrefixes from "../utils/phonePrefixes.json";
import { PhoneNumber, PhoneNumberListItem } from "../../types";
import { defaultPhonePrefix } from "../utils/const";

export const PhoneNumberComponent = defineComponent({
  props: {
    value: {
      type: Object as PropType<PhoneNumber>,
      required: true,
    },
    placeholder: {
      type: String,
    },
    size: {
      type: String,
    },
    maxLength: {
      type: String,
      default: "9",
    },
  },
  emits: ["update:value", "change"],
  setup(props, { emit }) {
    const number = ref("");
    const prefix = ref(defaultPhonePrefix);
    const phonePrefixOptions: Record<string, PhoneNumberListItem> =
      reactive(phonePrefixes);

    onMounted(() => {
      if (props.value) {
        number.value = props.value.number;
        prefix.value = props.value.prefix || defaultPhonePrefix;
      }
    });

    watch(
      () => props.value,
      (nv, ov) => {
        if (ov) {
          if (!ov.number) {
            number.value = nv.number;
          }
          if (!ov.prefix) {
            prefix.value = nv.prefix;
          }
        } else {
          prefix.value = nv.prefix;
          number.value = nv.number;
        }
      }
    );

    const update = () => {
      emit("update:value", {
        number: number.value,
        prefix: prefix.value,
        pattern: phonePrefixOptions[prefix.value].pattern,
      });
      emit("change");
    };

    return {
      phonePrefixOptions,
      number,
      prefix,
      update,
    };
  },
});

export default PhoneNumberComponent;
